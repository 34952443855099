.carousel-outer {
  overflow: hidden;
}

.carousel-wrapper {
  display: flex;
  transition: transform 1000ms;
}

.carousel-buttons {
  width: max-content;
  margin: 2rem auto;
  display: flex;
  gap: 1rem;
}

.carousel-buttons button {
  padding: 0 0.5rem;
  background: none;
  border: none;
  outline: 1px solid var(--color-main);
  cursor: pointer;
  transition: 200ms;
}

.carousel-buttons button svg {
  width: 30px;
  height: 30px;
  fill: var(--color-main);
  transition: 200ms;
  display: flex;
}

.carousel-buttons button:first-child svg {
  transform: rotateZ(180deg);
}

.carousel-buttons button:hover {
  background-color: var(--color-main);
}

.carousel-buttons button:hover svg {
  fill: white;
}
