.about-wrapper {
  margin-top: -4rem;
}

.about-container {
  padding-top: 2rem;
}

.about-content {
  display: flex;
  font-family: var(--font-family-text);
  gap: 2rem;
  margin-top: 2rem;
}

.about-decorated-text {
  flex: 25%;
}

.about-decorated-text p {
  color: var(--color-main);
}

.about-explanation {
  flex: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.about-explanation p {
  font-size: 0.85rem;
}

.about-art {
  flex: 25%;
  width: 100%;
  align-self: center;
}

.about-art img {
  width: 100%;
  filter: drop-shadow(15px 10px 0.75rem #003e84aa);
}

@media screen and (max-width: 1024px) {
  .about-content {
    flex-direction: column;
  }

  .about-art {
    width: 50%;
    align-self: center;
  }
}

@media screen and (max-width: 425px) {
  .about-art {
    width: 100%;
  }
}
