.favorites-wrapper {
  background-color: #f5f5f5;
}

.favorite-card {
  display: flex;
  gap: 4rem;
  margin-bottom: 4rem;
  align-items: center;
}

.favorite-card .favoriteText-wrapper {
  flex: 1;
}

.favorite-card .favorite-art {
  flex: 1;
}

.favorite-card img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  box-shadow: var(--painting-shadow);
}

.favorites-main {
  padding: 0 10rem 0 10rem;
}

.favorites-main h1 {
  margin-bottom: 4rem;
}

.favoriteText-date p {
  min-width: max-content;
}

@media screen and (max-width: 1024px) {
  .favorites-main {
    padding: 0 5rem 0 5rem;
  }
}

@media screen and (max-width: 850px) {
  .favorites-main {
    padding: 0 2rem 0 2rem;
  }
}

@media screen and (max-width: 650px) {
  .favorite-card {
    flex-direction: column;
  }

  .favorite-card:nth-child(3) {
    flex-direction: column-reverse;
  }
}
