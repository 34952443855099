.Footer-wrapper {
  background-color: black;
}

.Footer-Content {
  padding-top: 0;
  padding-bottom: 0;
}

.footer-menu {
  display: flex;
  font-family: var(--font-family-text);
  text-transform: uppercase;
  justify-content: space-between;
  padding: 2rem 0;

  border-top: 1px solid rgb(128, 128, 128, 0.2);
}
.footer-menu-links {
  display: flex;
  gap: 5rem;
  font-size: 0.75rem;
}
.footer-menu-links p {
  color: white;
  display: flex;
}

.footer-menu-links p:hover {
  color: var(--color-main);
}

.footer-totop button {
  background: none;
  color: var(--color-main);
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: 200ms;
}

.footer-totop button:hover {
  color: white;
}

.footer-totop button::before {
  content: "^ ";
}

.footer-socials {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer-socials a svg {
  transition: 200ms ease-in-out;
}

.footer-socials a:hover svg {
  fill: var(--color-main);
}

.footer-bottom {
  display: flex;
  gap: 4rem;
  align-items: flex-end;
  padding: 2rem 0;
  font-family: var(--font-family-text);
  font-size: 0.75rem;
  border-top: 1px solid rgb(128, 128, 128, 0.2);
  align-items: flex-start;
  padding-bottom: 4rem;
}

.footer-bottom p {
  color: rgba(167, 167, 167, 1);
}

.footer-bottom ul {
  min-width: max-content;
}

.footer-bottom li {
  color: rgba(167, 167, 167, 1);
}

.footer-bottom a {
  color: rgba(167, 167, 167, 1);
}

.footer-bottom div:last-child {
  min-width: max-content;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 850px) {
  .footer-bottom,
  .footer-menu {
    flex-direction: column;
    gap: 2rem;
  }

  .footer-bottom p {
    max-width: 500px;
  }
}

@media screen and (max-width: 425px) {
  .footer-menu-links {
    gap: 3rem;
  }
}
