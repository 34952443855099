.studio-content {
  display: flex;
  gap: 4rem;
  align-items: center;
}

.studio-para {
  flex: 1;
}

.studio-para h1 {
  padding-left: 0;
  margin-bottom: 2rem;
  text-align: start;
}

.studio-para p {
  font-family: var(--font-family-text);
  margin-bottom: 3rem;
}

.studio-para p:nth-child(2) {
  font-size: 1rem;
  font-weight: 500;
}

.studio-image {
  flex: 2;
}

.studio-image img {
  width: 100%;
  box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px,
    rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px,
    rgb(0 0 0 / 9%) 0px -3px 5px;
}

@media screen and (max-width: 850px) {
  .studio-content {
    flex-direction: column;
  }
}
