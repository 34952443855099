.contact-wrapper {
  display: flex;
}

.contact-title {
  padding-top: 4rem;
}

.contact-title h1 {
  font-size: 50px;
  margin-bottom: 4rem;
}

.contact-left {
  align-self: center;
  padding-right: 2rem;
  min-width: max-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.contact-right {
  flex: 1;
}

.horiz-line {
  width: 1.5px;
  height: 435px;
  background-color: var(--color-main);
  margin: auto;
}

.vert-line {
  width: 75%;
  height: 1.5px;
  background-color: var(--color-main);
  margin: 0.5rem auto;
}

.contact-info {
  margin-top: 2rem;
  font-family: var(--font-family-text);
  background-color: #f5f5f5;
  padding: 2rem;
  text-align: center;
  line-height: 30px;
  height: 187px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  overflow: hidden;
  transition: 200ms;
  cursor: pointer;
}

.contact-info:hover {
  transform: scale(1.1);
}

.contact-info::after {
  content: "";
  position: absolute;
  inset: 0px;
  top: -100%;

  aspect-ratio: 1/1;
  background-color: white;
  transform: rotate(45deg);
  transition: 500ms;
}

.contact-info.open-env::after {
  content: "";
  position: absolute;
  inset: 0px;
  top: -200%;
  aspect-ratio: 1/1;
  background-color: white;
  transform: rotate(45deg);
}

.contact-info::before {
  content: "Info";
  position: absolute;
  opacity: 1;
  background-color: #f5f5f5;
  inset: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 1rem;
  transition: opacity 200ms;
  pointer-events: none;
}

.contact-info.open-env::before {
  content: "Info";
  position: absolute;
  opacity: 0;
  background-color: #f5f5f5;
  inset: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 1rem;
}

.contact-info h2 {
  font-family: var(--font-family-text);
  font-size: 16px;
  font-weight: 400;
}

.contact-info p {
  font-size: 0.75rem;
}

.input-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 2rem;
  justify-self: flex-end;
}

.input-area {
  display: flex;
  flex-direction: column;
}

.contact-content {
  width: 100%;
  font-family: var(--font-family-text);
}

.input-area input,
.input-area textarea {
  background-color: #f5f5f5;
  border: none;
  padding: 1rem;
  margin: 1rem 0;
  resize: none;
  font-family: var(--font-family-text);
}

.contact-wrapper button {
  width: 50%;
}

.text-area-message {
  margin-top: 1rem;
}

@media screen and (max-width: 910px) {
  .horiz-line {
    height: 500px;
  }
}

@media screen and (max-width: 800px) {
  .contact-left {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .input-section {
    grid-template-columns: 1fr;
  }

  .contact-wrapper button {
    width: 100%;
  }
}
