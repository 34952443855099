.collectionHome-images {
  display: flex;
  gap: 2rem;
  margin: 4rem auto;
}

.collectionHome-content a {
  width: max-content;
  margin: auto;
  text-transform: uppercase;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  transition: 200ms;
}

.collectionHome-content a svg {
  margin: 0;
  fill: var(--color-text-main);
  padding: 0;
  width: 24px;
  margin-left: 1rem;
  transition: 200ms;
}

.collectionHome-content a:hover svg {
  fill: white;
}

@media screen and (max-width: 850px) {
  .collectionHome-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 525px) {
  .collectionHome-images {
    display: grid;
    grid-template-columns: 1fr;
  }
}
