.collection-con-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
  grid-auto-rows: 350px;
}

/* .collection-con-wrapper div {
  width: 100%;
  box-shadow: var(--painting-shadow);
} */

.collection-con-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.collection-con-wrapper div:nth-child(1) {
  grid-column: 1/3;
  grid-row: 1/1;
}

.collection-con-wrapper div:nth-child(4) {
  grid-row: 2/4;
  grid-column: 2/4;
}

.collection-con-wrapper div:nth-child(6) {
  grid-row: 4;
  grid-column: 1/3;
}

@media screen and (max-width: 1024px) {
  .collection-con-wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .collection-con-wrapper div:nth-child(1) {
    grid-column: auto;
    grid-row: auto;
  }

  .collection-con-wrapper div:nth-child(4) {
    grid-row: auto;
    grid-column: auto;
  }

  .collection-con-wrapper div:nth-child(6) {
    grid-row: auto;
    grid-column: auto;
  }
}

@media screen and (max-width: 550px) {
  .collection-con-wrapper {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
