.exhibition-wrapper {
  padding: 2rem;

  position: relative;
  flex: none;
}

.exhibition-wrapper img {
  aspect-ratio: 1/1;
  width: 100%;
  object-fit: cover;
  box-shadow: var(--painting-shadow);
}

.exhibition-nail {
  background: #313a3a;
  width: 7.5px;
  height: 7.5px;
  border-radius: 100%;
  margin: auto;
  position: relative;
}

.exhibition-nail::before {
  position: absolute;
  content: "+";
  inset: -2.5px -5px 0px 1.1px;
  color: gray;
  z-index: 10;
  font-size: 0.6rem;
  transform: rotate(25deg);
}

.exhibition-line {
  border: 2px solid #313a3a;
  width: 30px;
  height: 30px;
  margin: auto;
  margin-bottom: -15px;
  transform: rotateZ(45deg);
}

.exhibition-artwork {
  position: relative;
  /* overflow-x: hidden; */
  display: flex;
}

.exhibition-artwork::before {
  position: absolute;
  content: "";
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 40%,
    rgba(0, 0, 0, 0.9) 100%
  );
  inset: 0 0 0 0;
  opacity: 0;
  transition: 200ms;
}

.exhibition-hidden {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* text-align: center; */
  transform: translateY(-25%);
  transition: transform 300ms, opacity 100ms;
  opacity: 0;
  pointer-events: none;
  padding: 1rem;
  font-size: 0.9rem;
  flex-direction: column;
}

.exhibition-hidden .vert-line {
  margin: 0.5rem 0;
  align-self: flex-start;
}

.exhibition-wrapper .exhibition-hidden p {
  color: white;
  font-family: var(--font-family-text);
}

.exhibition-artwork:hover .exhibition-hidden {
  transform: translateY(0);
  opacity: 1;
  /* transition: transform 200ms opacity 200ms 100; */
}

.exhibition-artwork:hover::before {
  opacity: 1;
}

.exhibition-wrapper h2 {
  color: var(--color-text-main);
  font-family: var(--font-family-title);
  font-size: 1.5rem;
  font-weight: 500;
  /* line-height: 1.2; */
  /* text-align: center; */

  padding: 1rem;
}

.upper {
  text-transform: uppercase;
  padding: 0 1rem;
  color: var(--color-main);
  font-family: var(--font-family-text);
  font-size: 0.75rem;
  /* font-weight: 400; */
  /* text-align: center; */

  font-weight: bold;
}
