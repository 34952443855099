.collectionItem-wrapper {
  width: 100%;
}

.collectionItemInner {
  height: 100%;
  width: 100%;

  box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px,
    rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px,
    rgb(0 0 0 / 9%) 0px -3px 5px;

  overflow: hidden;
  position: relative;
}

.collectionItemInner::before {
  content: "";
  position: absolute;
  inset: 5px;
  background-color: #00000050;
  border: 5px solid #ffffffdd;
  opacity: 0;
  z-index: 10;
  transition: opacity 500ms ease-in-out;
}

.collectionItemInner img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: 1000ms ease-in-out;
  position: relative;
}

.collectionItemInner:hover::before {
  opacity: 1;
}

.collectionItemInner:hover img {
  transform: rotate(15deg) scale(1.5);
}

.collectionItem-hidden {
  z-index: 100;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: max-content;
  transition: 200ms;
}

.collectionItem-hidden h1 {
  color: white;
  font-family: var(--font-family-text);
  font-size: 25px;
  font-weight: 500;
}

.collectionItemInner:hover .collectionItem-hidden {
  opacity: 1;
}

/* .collection-con-wrapper {
} */
