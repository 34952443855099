@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:wght@300;400;500;600;700&display=swap");

:root {
  --font-family-title: "Playfair Display", serif;

  --font-family-text: "DM Sans", sans-serif;

  --color-bg: radial-gradient(circle at 50% 50%, #fefefe 0%, #fdfcfd 100%);
  --color-text-main: #292929;
  --color-p: #32353b;

  /* --color-main: rgba(184, 157, 79, 1); */
  --color-main: #d7c293;
  --painting-shadow: 2px 2px 0 #7e7e7e, 3px 3px 4px #000000e6,
    6px 6px 12px 4px #0000001a, 0 0 14px 4px #00000026,
    -2px -2px 1px #00000080 inset, 10px 2px 10px #00000059,
    20px 4px 10px #00000026;
}
