html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.container {
  max-width: 1450px;
  margin-left: auto;
  margin-right: auto;
}

.section-padding {
  padding: 4rem 4rem;
}

a {
  color: unset;
  text-decoration: none;
}

body {
  background: var(--color-bg);
}

h1,
h2 {
  font-family: var(--font-family-title);
}

h2,
h3 {
  color: var(--color-text-main);
}

h3 {
  font-family: var(--font-family-text);
}

p {
  color: var(--color-p);
}

button {
  border: none;
  cursor: pointer;
}

.title {
  color: var(--color-text-main);
  margin: auto;
  text-align: center;
  font-family: var(--font-family-title);
  font-size: 4.5rem;
  font-weight: 500;
  padding: 1rem;
}

/* .buttonStyle {
  font-family: var(--font-family-text);
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid var(--color-text-main);
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.buttonStyle:after {
  content: "";
  background-color: var(--color-main);
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.buttonStyle:hover:after {
  top: 0px;
  left: 0px;
}

@media (min-width: 768px) {
  .buttonStyle {
    padding: 13px 50px 13px;
  }
} */

.buttonStyle {
  background-color: var(--color-main);
  padding: 1rem 3rem;
  border-radius: 500px;
  font-size: 0.75rem;
  color: var(--color-text-main);
  font-family: var(--font-family-text);
  text-transform: uppercase;
  box-shadow: rgb(215, 194, 147) 0px 20px 30px -10px;

  transition: 200ms;
}

.buttonStyle:hover {
  box-shadow: rgba(215, 194, 147, 0.8) 0px 8px 24px;
  color: white;
}

@media screen and (max-width: 700px) {
  .section-padding {
    padding: 4rem;
  }

  .title {
    font-size: 3rem;
  }
}

@media screen and (max-width: 550px) {
  .section-padding {
    padding: 2rem;
  }
}
