.hero-wrapper {
  background-color: #f5f5f5;
}

.hero-container {
  display: flex;
  align-items: center;
}

.hero-title {
  flex: 1;
  margin-right: -8rem;
  z-index: 10;
  padding-top: 4rem;
}

.paralax {
  position: relative;
  z-index: 6;
}

.hero-title h1 {
  color: var(--color-text-main);
  font-weight: 500;
  font-size: 80px;
  line-height: 90px;
  font-weight: 400;
  letter-spacing: -5px;
  font-family: var(--font-family-title);
}

.hero-img {
  flex: 1.25;
  z-index: 5;
  padding-top: 4rem;
  position: relative;
  transform: var(--paralax-transform);
}

.hero-img img {
  width: 100%;
  height: 100%;
  box-shadow: var(--painting-shadow);
  position: relative;
}

.hero-img::after {
  content: "River Blue";
  position: absolute;
  z-index: 1000;
  bottom: 3.5px;
  left: 0;
  font-family: var(--font-family-title);
  font-size: 1.5rem;
  color: white;
  padding: 1rem 1rem 1rem 1rem;

  background: rgba(0, 0, 0, 0.2);
  border-radius: 0 0 0 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.hero-img::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.6) 0%,
    transparent 20%,
    transparent 100%
  );
  top: 4rem;
  bottom: 0.25rem;
  left: 0;
  right: 0;
  z-index: 5;
}

.hero-title-explanation {
  padding: 4rem 4rem 0 4rem;
  line-height: 1.5rem;
}

.hero-title-explanation h2 {
  font-family: var(--font-family-title);
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1rem;
}

.small-explanation {
  margin-bottom: 1rem;
}

.hero-title-explanation p {
  font-family: var(--font-family-text);
  max-width: 400px;
}

@media screen and (max-width: 850px) {
  .hero-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .hero-title-explanation {
    padding: 4rem 0 0 4rem;
  }

  .hero-title {
    margin-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .hero-img {
    transform: none;
  }
}

@media screen and (max-width: 425px) {
  .hero-title h1 {
    font-size: 70px;
  }

  .hero-title-explanation {
    padding: 2rem 0 0 2rem;
  }
}
