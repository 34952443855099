.favoriteText-date {
  display: flex;
  gap: 4rem;
  align-items: center;
  margin-bottom: 2rem;
}

.favoriteText-date p {
  color: var(--color-main);
  text-transform: uppercase;
  font-family: var(--font-family-text);
}

.vert-line {
  width: 150px;
  height: 1px;
  background: var(--color-main);
}

.favoriteText-content h1 {
  font-family: var(--font-family-title);
  font-size: 35px;
  margin-bottom: 2rem;
  color: var(--color-text-main);
}

.favoriteText-content {
  font-family: var(--font-family-text);
  font-size: 1rem;
  color: var(--color-text-main);
}
