.collection-wrapper {
  padding-top: 8rem;
}

.collection-wrapper h2 {
  color: var(--color-main);
  font-family: var(--font-family-text);
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
}

.horz-line {
  width: 1px;
  margin: auto;
  background-color: var(--color-main);
  height: 80px;
}
