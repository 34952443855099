.Menu-Wrapping {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: transparent;
  border-bottom: 1px solid rgba(236, 236, 236, 1);
}

.Menu-Content {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  align-items: center;
}

.scrolling .Menu-Content {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.Menu-Content h1 {
  font-family: var(--font-family-title);
}

.Menu-Menu-Wrapper {
  display: flex;
  gap: 5rem;
  font-family: var(--font-family-text);
  text-transform: uppercase;
  align-items: center;
  background: transparent;
}

.Menu-Menu-Wrapper p {
  font-size: 0.75rem;
  color: var(--color-text-main);
  width: max-content;
  position: relative;
}

.Menu-Menu-Wrapper p::before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--color-text-main);
  transform: scale(0);
  transition: transform ease-in-out 200ms;
  z-index: -1;
}

.Menu-Menu-Wrapper p:hover::before {
  transform: scale(1);
}

.scrolling {
  background: #f5f5f5;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  transform: scaleY(0.95);
  transform-origin: top;
  transition: 200ms;

  background: rgba(245, 245, 245, 0.5019607843137255);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.scrolling .Mobile-Menu-Open {
  transform: scaleY(1.06);
  transform-origin: top;
}

.scrolling .Menu-Menu-Wrapper p::before {
  background: var(--color-text-main);
}

.scrolling p {
  color: var(--color-text-main);
}

.Mobile-Menu {
  display: none;
  font-family: var(--font-family-text);
}

.Mobile-Menu-Open {
  position: absolute;
  top: 0;
  right: 0;

  height: 110vh;
  width: max-content;
  padding: 2rem;
  background: var(--color-bg);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  background: rgba(245, 245, 245, 0.9);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  align-items: flex-end;
}

.Close-Mobile-Menu {
  align-self: end;
  display: flex;
}

.Close-Mobile-Menu {
  fill: var(--color-text-main);
}

.open-svg {
  align-self: flex-start;
}

.Menu-Content .h p:first-child {
  pointer-events: none;
  opacity: 0.5;
}

.Menu-Content .co p:nth-child(2) {
  pointer-events: none;
  opacity: 0.5;
}

.Menu-Content .c p:nth-child(3) {
  pointer-events: none;
  opacity: 0.5;
}

@media screen and (max-width: 1050px) {
  .Menu-Menu-Wrapper {
    display: none;
  }

  .Mobile-Menu {
    display: flex;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.banana {
  -webkit-animation: slide-in-right-back 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both;
  animation: slide-in-right-back 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    reverse both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right-back {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right-back {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
